/* header */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.warlocks {
  color: purple;
  text-shadow: 1px 1px 2px whitesmoke;
}

.generate-button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: whitesmoke;
  margin-top: 2rem;
}

.generate-button:hover {
  background-color: purple;
}

/* options */
#options {
  padding: 3rem 0;
  width: 60%;
  max-width: 750px;
  margin: 3rem auto;
  text-align: center;
}

#options-heading {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 3rem;
  font-style: italic;
  color: darkred;
}

#options-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.option {
  margin-top: 2rem;
  padding: 3rem;
}

/* stat-block */
#stats {
  background-color: #282c34;
  padding: 1rem;
}

#stat-block {
  padding: 3rem;
  width: 60%;
  max-width: 750px;
  margin: 3rem auto;
  box-shadow: 5px 10px 20px darkred;
  background-color: whitesmoke;
  border-radius: 0.5rem;
}

.warlock-name {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 3rem;
  color: darkred;
  margin-bottom: 16px;
  margin-top: 0;
}
.running-title {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-style: italic;
  color: #282c34;
  font-weight: 600;
}

.title {
  font-weight: 800;
}

hr {
  border: darkred 1px solid;
}

h3 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.5rem;
  color: darkred;
}

.attribute-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

/* attributes */
.all-attributes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.attribute-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.attribute {
  background-color: whitesmoke;
  width: 10%;
  max-width: 7rem;
  min-width: 5rem;
  border: 2px solid darkred;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 2px 4px 8px #282c34;
}

.attribute .value {
  font-size: 1.5rem;
  font-weight: 900;
}

.attribute .modifier {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.regenerate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
}

/* footer */
.Footer {
  background-color: #282c34;
  font-size: 0.8rem;
  color: whitesmoke;
  padding: 1rem;
}